input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

:root {
    --toastify-color-success: #000 !important;
    --toastify-color-progress-light: #000 !important;
}

.softDeleted {
    opacity: 0.3;
}
